// eslint-disable-next-line no-unused-vars
const JournalEditingJson = (_this) => {
  return {
    countCells: 18,
    orgMassive: [
      {
        dataField: 'rowOrder',
        cols: 1,
        rows: 1,
        text: '№',
        // sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantsSelectionJsonNum',
        styles: '',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left; padding-left: 16px`,
      },
      {
        dataField: 'id',
        cols: 1,
        rows: 1,
        text: 'id',
        // sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantsSelectionJsonNum',
        styles: '',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left; padding-left: 16px`,
      },
      {
        dataField: 'issn',
        text: 'ИССН',
        cols: 1,
        rows: 1,
        variable: true,
        class: 'applicantsSelectionJsonOrgSpec',
        styles: '',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left; padding-left: 16px`,
      },
      {
        dataField: 'eissn',
        cols: 1,
        rows: 1,
        text: 'Электронный ИССН',
        // sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantsSelectionJsonOrgSpec',
        styles: '',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left; padding-left: 16px`,
      },
      {
        dataField: 'name',
        cols: 4,
        rows: 1,
        text: 'Наименование',
        // sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantsSelectionJsonOrgInfo',
        styles: '',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: center`,
      },
      {
        dataField: 'country',
        cols: 1,
        rows: 1,
        text: 'Страна',
        variable: true,
        class: 'applicantsSelectionJsonOrgSpec',
        styles: '',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left; padding-left: 16px`,
      },
      {
        dataField: 'rinc',
        cols: 1,
        rows: 1,
        text: 'Входит в РИНЦ',
        variable: true,
        class: 'applicantsSelectionJsonOrgSpec',
        styles: '',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left; padding-left: 16px`,
      },
      {
        dataField: 'rincCore',
        cols: 1,
        rows: 1,
        text: 'Входит в ядро РИНЦ',
        // sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantsSelectionJsonOrgSpec',
        styles: '',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left; text-align: center`,
      },
      {
        dataField: 'vak',
        cols: 1,
        rows: 1,
        text: 'Входит в ВАК',
        // sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantsSelectionJsonOrgSpec',
        styles: '',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: center`,
      },
      {
        dataField: 'wosCc',
        text: 'Входит в Web Of Science',
        cols: 1,
        rows: 1,
        variable: true,
        class: 'applicantsSelectionJsonOrgSpec',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left; padding-left: 16px`,
      },
      {
        dataField: 'sjrQuartile',
        text: 'Квартиль в Web Of Science',
        cols: 1,
        rows: 1,
        variable: true,
        class: 'applicantsSelectionJsonOrgSpec',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left; padding-left: 16px`,
      },
      {
        dataField: 'scopus',
        text: 'Входит в Scopus',
        cols: 1,
        rows: 1,
        variable: true,
        class: 'applicantsSelectionJsonOrgSpec',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left; padding-left: 16px`,
      },
      {
        dataField: 'jcrQuartile',
        text: 'Квартиль в Scopus',
        cols: 1,
        rows: 1,
        variable: true,
        class: 'applicantsSelectionJsonOrgSpec',
        headerClass: '',
        headerStyles: `border-left: none; justify-content: left; padding-left: 16px`,
      },
      {
        dataField: 'actions',
        text: '',
        cols: 2,
        rows: 1,
        variable: true,
        styles: 'border-left: none; display: flex; flex-direction: row',
        headerStyles: `border-left: none; justify-content: left; padding-left: 16px`,
      },
    ],
  };
}

export default JournalEditingJson